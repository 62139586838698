<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-sheet
    class="mx-auto"
    color="transparent"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        sm12
        xs12
      >
        <v-parallax
          :alt="AppName"
          :src="require('@images/slider.jpeg')"
          height="800"
          jumbotron
        >
          <v-layout
            align-center
            column
            justify-center
            class="text-center"
          >
            <div :class="`text-h${AppIsSmall ? 4: 1} font-weight-thin mb-4`">
              {{ parseAttribute('homePage.header_title') }}
            </div>
            <div :class="`${AppIsSmall ? 'text-body-2' : 'text-h6'} font-weight-medium`">
              {{ parseAttribute('homePage.header_subtitle') }}
            </div>
            <v-btn
              :to="route(APP_ROUTES.public.apply)"
              class=" ma-6"
              large
            >
              {{ parseAttribute('homePage.header_btn') }}
            </v-btn>
          </v-layout>
        </v-parallax>
      </v-flex>
    </v-layout>
    <v-container
      id="about_us"
      class="accent"
      fluid
    >
      <v-row>
        <v-col
          class="text-center py-16"
          cols="12"
        >
          <div
            class="text-h4 mb-15"
            v-text="parseAttribute('about_us')"
          />
          <div
            class="text-h6"
            v-text="AppSettings.about_us"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-container
      id="services"
      class="accent py-16"
      fluid
    >
      <v-row class="mb-10">
        <v-col
          class="text-center"
          cols="12"
        >
          <div
            class="text-h4"
            v-text="parseAttribute('services')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-row>
              <template
                v-for="(item,index) in $t('homePage.services')"
              >
                <v-hover
                  :key="`service-${index}`"
                  v-slot="{ hover }"
                  open-delay="100"
                >
                  <v-col
                    :class="{'text-center pointer service-card': !0, 'on-hover': hover}"
                    cols="12"
                    md="3"
                  >
                    <v-avatar
                      :color="hover ? `secondary` : 'primary'"
                      class=" elevation-10"
                      size="140px"
                    >
                      <v-img
                        :src="`/svg/s${index+1}.svg`"
                        contain
                        height="100px"
                      />
                    </v-avatar>
                    <v-card
                      :color="hover ? 'primary' : 'secondary'"
                      :elevation="hover ? 0 : 10"
                      style="margin-top: -3rem"
                    >
                      <v-card-text class="text-center">
                        <div class="text-h6">
                          {{ item.text }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-hover>
              </template>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="$route.query.l">
      <v-row
        justify="center"
      >
        <v-col cols="auto">
          <app-btn :to="route(APP_ROUTES.auth.login)">
            {{ parseAttribute('login') }}
          </app-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

import { MetaInfoMixin } from '@mixins'

export default {
  name: 'HomePage',
  mixins: [MetaInfoMixin],
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.service-card {
  transition: opacity .4s ease-in-out;
}

.service-card:not(.on-hover) {
  opacity: 0.7;
}

</style>
